import React, {useState} from "react";
import TrashImage from "../../assets/images/admin/trash.svg"
import Language from "../../helpers/language";
import {Drip7Button} from "../drip7_button";
import { Drip7Input } from "../drip7_input";
import { Drip7TextArea } from "../drip7_textarea";

export const ContentImageWidget = props => {
    const { access, question, content, raw_image, showToast, onFile, onChange } = props;
    const [state, setState] = useState({
        filename: "",
        raw_file: raw_image,
        raw_img: raw_image ? URL.createObjectURL(raw_image) : null,
    });
    const { filename, raw_file, raw_img } = state;

    const question_uid = question.uid || "";
    const image = raw_img || content.image || "";
    const alt_text = content.alt_text || "";
    const text = content.text || ""

    //File ref required to access the file browser
    const fileRef = React.useRef();

    //Funky pass through to open the file browse
    const handleFileClick = () => {
        if ( !access.write || !fileRef.current ) {
            return
        }

        fileRef.current.click();
    };

    //We have a selected file
    const handleFileChange = e => {
        if ( !access.write ) {
            return
        }

        const raw_file = e.target.files[0];
        if ( raw_file === undefined ) {
            return
        }

        onFile( 'image', raw_file )
        setState(prev => ({ ...prev,
            filename: raw_file.name,
            raw_file,
            raw_img: URL.createObjectURL(raw_file),
        }))
    };

    const handleRemoveImage = e => {
        if ( !access.write ) {
            return
        }

        onChange('content', { ...content, image: null });
    }

    const handleChange = e => {
        if ( !access.write ) {
            return
        }

        console.log(e)
        onChange('content', { ...content, [e.target.id]: e.target.value });
    };

    return (<div className='basic-modal__field-container--add-question basic-modal__add-question--image px-1'>
        {image ? (<>
                <img
                    className='daily-questions__image'
                    src={image}
                    alt={content.alt_text}
                    onClick={handleFileClick}
                />
                <div className='delete-user'
                     onClick={handleRemoveImage}>
                    <img src={TrashImage} alt='trash image'/>
                    {Language.getTitleCase( 'remove image' )}
                </div>
            </>) : (<div className='basic-modal__add-image-container'
                         onClick={handleFileClick}>
                <svg
                    className='basic-modal__add-image-icon'
                    width='45'
                    height='34'
                    viewBox='0 0 45 34'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        opacity='0.2'
                        d='M40.7812 34C43.0664 34 45 32.1543 45 29.7812V4.46875C45 2.18359 43.0664 0.25 40.7812 0.25H4.21875C1.8457 0.25 0 2.18359 0 4.46875V29.7812C0 32.1543 1.8457 34 4.21875 34H40.7812ZM9.84375 5.17188C12.4805 5.17188 14.7656 7.45703 14.7656 10.0938C14.7656 12.8184 12.4805 15.0156 9.84375 15.0156C7.11914 15.0156 4.92188 12.8184 4.92188 10.0938C4.92188 7.45703 7.11914 5.17188 9.84375 5.17188ZM5.625 28.375V24.1562L11.8652 17.916C12.3047 17.4766 12.9199 17.4766 13.3594 17.916L18.2812 22.75L30.1465 10.8848C30.5859 10.4453 31.2012 10.4453 31.6406 10.8848L39.375 18.5312V28.375H5.625Z'
                        fill='#607D8B'
                    />
                </svg>

                <p className='basic-modal__upload-label'>
                    <span>{Language.getSentenceCase( 'No image file selected' )}</span>
                </p>
            </div>)}

        <div className='basic-modal__wrapper basic-modal__wrapper--add-badge mt-2'>
            <div className='video-input-wrap'>
                <label className='basic-modal__label' htmlFor='text'>
                    {Language.getTitleCase( 'Image' )}
                </label>
                <input
                    type='file'
                    accept='image/*'
                    ref={node => (fileRef.current = node)}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
                <div className='inline-flex w-fit'>
                    <Drip7Input
                        id='user-file'
                        placeholder={Language.getSentenceCase( 'No File Selected' )}
                        readOnly
                        value={filename}
                    />
                    <Drip7Button
                        disabled={ !access.write}
                        nameBtn='choose-file'
                        onClick={handleFileClick}>
                        {Language.getTitleCase( 'Choose File' )}
                    </Drip7Button>
                </div>
            </div>
        </div>

        <div className='basic-modal__field--add-question width-100 mt-3'>
            <Drip7Input
                className="mt-2"
                label={Language.getTitleCase( 'Alt Text' )}
                placeholder={Language.getSentenceCase( 'Alt text for image' )}
                id='alt_text'
                readOnly={ !access.write}
                value={alt_text}
                onChange={handleChange}
            />
        </div>

        <Drip7TextArea
            className='basic-modal__field--add-question width-100'
            label='Question'
            placeholder={Language.getSentenceCase( 'Type question text here' ) + '...'}
            id='text'
            readOnly={ !access.write}
            onChange={handleChange}
            value={text}
        />
    </div>
    );
};
