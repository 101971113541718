import React, {useState, useEffect, useRef} from "react";
import Language from "../helpers/language";
import Util from "../helpers/util";
import Chart from "react-apexcharts";
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

export const CountCard = props => {
    const { cascade, tenant_uid, showToast } = props;

    const chart = useRef(null)
    const [state, setState] = useState({
        local_tenant_uid: '',
        local_cascade: null,
        stats: []
    });
    const { local_tenant_uid, local_cascade, stats } = state

    useEffect(() => {
        //Bail
        if (tenant_uid == "" || (tenant_uid == local_tenant_uid && cascade == local_cascade)) {
            return;
        }

        //Query the stats
        Util.fetch_js( "/dashboard/counts/", { tenant_uid, cascade },
            js => {
                const stats = js.stats
                setState(prev => ({...prev,
                    stats: Object.keys(stats).map((k) => { 
                        const change = Math.round(((stats[k].stat-stats[k].previousStat)/stats[k].previousStat) * 100)
                        return {name: k, 
                            stat: stats[k].stat, 
                            previousStat: stats[k].previousStat, 
                            change:`${change}%`, 
                            changeType: stats[k].previousStat != 0 ? (change >= 0 ? 'increase' : 'decrease') : ''} 
                    }),
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState(prev => ({...prev,
            local_tenant_uid: tenant_uid,
            local_cascade: cascade,
        }))
    }, [props]);

    return (
        <div>
            <h3 className="text-base font-semibold text-gray-900">{Language.getTitleCase('Last 30 days')}</h3>
            <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
            {stats.map((item) => (
                <div key={item.name} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">{Language.getTitleCase(item.name)}</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-drip7">
                        {item.stat}
                        <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
                    </div>
    
                    {item.changeType !== '' && (
                        <div
                            className={Util.classNames(
                                item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                                'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                            )}
                        >
                        {item.changeType === 'increase' ? (
                                <ArrowUpIcon aria-hidden="true" className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-green-500" />
                            ) : (
                                <ArrowDownIcon aria-hidden="true" className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-red-500" />
                            )
                        }
        
                        <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                            {item.change}
                        </div>
                    )}
                </dd>
                </div>
            ))}
            </dl>
        </div>
    )
};
