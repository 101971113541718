import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { QuestionsTab } from "./questions_tab";
import { CategoriesTab } from "./categories_tab";
import { PhishTemplatesTab } from "./phish_templates_tab";
import { QueueTab } from "./queue_tab.js";
import {Access, ContentType, CurriculumUsage} from "../../helpers/consts";
import { useStore } from "../../store";


import { QueueTrackListWidget } from "../../components/admin/queue_track_list_widget";
import { QueueTrackGraphicalWidget } from "../../components/admin/queue_track_graphical_widget";
import {CurriculumTab} from "./curriculum_tab";


export const Phish = (props) => {
    const { tenant_uid, tenants, showToast } = props;

    const [state, setState] = useState({
        tenant_uid: "",
        show_answers: false,
        question_categories: [],
        categories: [],
        selected_idx: 0
    })
    const { show_answers, question_categories, categories, selected_idx } = state

    const updateContent = (show_answers) => {
        //Get a list of all members for this tenant
        const payload = {
            tenant_uid,
            show_answers,
            access: Access.ALL,
            include_types: [ContentType.Phish],
        };
        Util.fetch_js( "/question/list/", payload,
            js => {
                //Seems wierd, but call another download that is much slower with more detailed information
                if ( !show_answers ) {
                    updateContent( true )
                }

                //Store the categories!
                setState(prev => ({...prev,
                    show_answers: true,
                    question_categories: js.categories
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        if ( show_answers ) {
            //Get all the categories
            Util.fetch_js( "/category/list/", { tenant_uid, access: Access.ALL, phish_only: true },
                js => {
                    //Store the categories!
                    setState(prev => ({...prev,
                        categories: js.categories
                    }))
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }

        //Setup the tenant_uid to block multiloads
        setState(prev => ({
            ...prev,
            tenant_uid,
        }))
    }

    useEffect(() => {
        props.onPathChange("phish")
    }, [])

    useEffect(() => {
        if ( props.tenant_uid != state.tenant_uid ) {
            updateContent( show_answers )
        }
    }, [props])

    return (
        <div className='questions main-modal'>
            <nav className='central__nav'>
                <ul className='central__nav-list'>

                    <li className={ "central__nav-item central__nav-item--wide " + (selected_idx === 0 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 0 }))}>
                        <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z" fill='currentColor' />
                        </svg>
                        {Language.getTitleCase('phish queue')}
                    </li>

                    <li className={ "central__nav-item central__nav-item--wide " + (selected_idx === 1 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 1 }))}>
                        <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill='var(--drip7-primary-color)' d="M44.8047 15.4863L24.9063 8.26801C23.9219 7.91066 22.8672 7.91066 21.8828 8.26801L2.125 15.4863C1.42188 15.7722 1 16.4154 1 17.1301C1 17.9162 1.42188 18.5595 2.125 18.8453L6.34375 20.3462C5.5 21.4897 4.86719 22.7761 4.58594 24.2055C3.8125 24.5628 3.25 25.349 3.25 26.2781C3.25 26.9927 3.53125 27.636 4.02344 28.0648L2.33594 38.7136C2.19531 39.4283 2.75781 40 3.39063 40H7.53906C8.17188 40 8.73438 39.4283 8.59375 38.7136L6.90625 28.0648C7.39844 27.636 7.75 26.9927 7.75 26.2781C7.75 25.5634 7.32813 24.9202 6.83594 24.5628C7.11719 23.2764 7.75 22.1329 8.59375 21.2038L21.9531 26.0637C22.9375 26.421 23.9922 26.421 24.9766 26.0637L44.8047 18.8453C45.5078 18.5595 46 17.9162 46 17.1301C46 16.4154 45.5078 15.7722 44.8047 15.4863ZM25.6797 28.2077C24.9766 28.4936 24.2031 28.565 23.5 28.565C22.7266 28.565 21.9531 28.4936 21.25 28.2077L10.9844 24.4913L10 34.7113C10 37.6415 15.9766 40 23.5 40C30.8828 40 37 37.6415 37 34.7113L35.9453 24.4913L25.6797 28.2077Z" />
                        </svg>
                        {Language.getTitleCase('phish campaign')}
                    </li>

                    <li className={ "central__nav-item central__nav-item--wide " + (selected_idx === 2 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 2 }))}>
                        <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z" fill='currentColor' />
                        </svg>
                        {Language.getTitleCase('phish templates')}
                    </li>
                </ul>
            </nav>

            {selected_idx === 0 &&
                <QueueTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                    isPhishing={true}
                />
            }

            {selected_idx === 1 && (
                <CurriculumTab
                    tenant_uid={tenant_uid}
                    tenants={props.tenants}
                    usage={CurriculumUsage.CAMPAIGN}
                    showToast={showToast}
                />
            )}

            {selected_idx === 2 && (
                <PhishTemplatesTab
                    tenant_uid={tenant_uid}
                    tenants={props.tenants}
                    categories={categories}
                    question_categories={question_categories}
                    showToast={showToast}
                    onRefreshCategories={() => updateContent( show_answers )}
                />
            )}
        </div>
    );
}
