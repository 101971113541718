import React, {useEffect, useState} from "react"

import { useStore } from "../../store"
import Util from "../../helpers/util"
import Language from "../../helpers/language"
import { Drip7OriginalModal } from "../../modals/drip7_original_modal"

import { ConfirmModal } from "../../modals/confirm_modal"
import { Access, CurriculumUsage } from "../../helpers/consts"
import { CurriculumModal } from "../../modals/admin/curriculum_modal"
import {Drip7Button} from "../../components/drip7_button";


export const CurriculumTab = props => {
    const { usage, tenant_uid, tenants, showToast } = props

    const [state, setState] = useState({
        search: "",
        confirm: null,
        curriculums: [],
        curriculum_selected: {},
        default_delivery: "",
        show_curriculum: null
    })
    const { search, curriculums, curriculum_selected, default_delivery, show_curriculum, confirm } = state

    useEffect(() => {
        updateCurriculumList()
    }, [tenant_uid])

    const filterCurriculum = (curriculums) => {
        const search_lc = search.toLowerCase()
        const delivery_lc = default_delivery.toLowerCase()

        //Only keep
        let result = []
        curriculums.forEach(curriculum => {
            if ( search_lc !== "" &&
                curriculum.name.toLowerCase().indexOf(search_lc) < 0 ) {
                return
            }
            if ( delivery_lc !== "" &&
                curriculum.default_delivery.toLowerCase() !== default_delivery ) {
                return
            }

            result.push(curriculum)
        })

        return result
    }

    const updateCurriculumList = () => {
        //Get a list of all curriculums for this tenant
        Util.fetch_js( "/curriculum/list/", { tenant_uid, access: Access.EXEC, usage },
            js => {
                setState(prev => ({...prev, curriculums: js.curriculums }))
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        )
    }

    const handleSelectAll = (checked) => {
        let curriculum_selected = {}

        //Get data
        let filtered_curriculum = filterCurriculum(curriculums)

        //Should we check or send empty list?
        if (checked) {
            filtered_curriculum.forEach(c => curriculum_selected[c.uid] = true)
        }

        setState(prev => ({...prev, curriculum_selected }))
    }

    const handleSelectCurriculum = (checked, uid) => {
        //update my list
        if (checked) {
            curriculum_selected[uid] = true
        }
        else {
            delete curriculum_selected[uid]
        }

        setState(prev => ({...prev, curriculum_selected }))
    }

    const handleChange = (e) => {
        setState(prev => ({...prev, [e.target.id]: e.target.value }))
    }

    const handleCurriculum = (show_curriculum) => {
        setState(prev => ({...prev, show_curriculum }))
    }

    const handleCloseCurriculum = () => {
        updateCurriculumList()
        setState(prev => ({...prev, show_curriculum: null }))
    }

    const handleRemoveCurriculum = () => {
        Util.fetch_js(
            "/curriculum/remove/",
            { curriculum_uids: Object.keys(curriculum_selected) },
            js => {
                updateCurriculumList()
                showToast(Language.getSentenceCase('removed'), 'successful')

                setState(prev => ({...prev, curriculum_selected: {}, confirm: null }))
            },
            (err, code) => {
                showToast(err, 'failure')
            }
        )
    }

    const fc = filterCurriculum(curriculums)
    const filtered_curriculums = [
        ...fc.filter(c => c.tenant.uid === tenant_uid),
        ...fc.filter(c => c.tenant.uid !== tenant_uid)
    ]

    const confirmAction = action => {
        let yes = null
        let msg = null
        if (action === handleRemoveCurriculum) {
            yes = Language.getTitleCase('remove')
            msg = Language.getSentenceCase('remove curriculum?')
        }
        else {
            throw Language.getSentenceCase('Program error, Unknown action') + ": " + action
        }

        //Setting a confirm action, will load message box, and if accepted saves
        const new_confirm = { action, msg, yes, no: Language.getTitleCase('cancel') }
        setState(prev => ({...prev, confirm: new_confirm }))
    }

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by name') + '...'}
                            id='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        {Object.keys(curriculum_selected).length > 0 && (
                            <Drip7Button
                                onClick={() => confirmAction( handleRemoveCurriculum )}>
                                {Language.getTitleCase('Remove Campaign')}
                            </Drip7Button>
                        )}

                        <Drip7Button
                            id='addContent'
                            onClick={() => handleCurriculum({ usage }) }>
                            {Language.getTitleCase('create campaign')}
                        </Drip7Button>
                    </div>
                </div>

                <table
                    className='management__table'
                    role='region'
                    aria-live='polite'>
                    <tbody>
                        <tr className='management__table-row management__table-row--head'>
                            <th className='management__table-head management__table-head--input'>
                                <div className='d7__checkbox-container'>
                                    <input
                                        className='d7__checkbox'
                                        aria-label="checkbox"
                                        type='checkbox'
                                        onChange={e =>
                                            handleSelectAll(
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <span className='dash'></span>
                                </div>
                            </th>
                            <th className='management__table-head management__table-head--name'>
                                <div className='management__table-heading'>
                                    {Language.getTitleCase('name')}
                                </div>
                            </th>
                            <th className='management__table-head management__table-head--languages'>
                                <div className='management__table-heading'>
                                    {Language.getTitleCase('tenant')}
                                </div>
                            </th>
                            <th className='management__table-head management__table-head--languages'>
                                <div className='management__table-heading'>
                                    {Language.getTitleCase('usage')}
                                </div>
                            </th>
                            <th className='management__table-head management__table-head--languages'>
                                <div className='management__table-heading'>
                                    {Language.getTitleCase('questions')}
                                </div>
                            </th>
                        </tr>
                        {Object.entries(filtered_curriculums).map(
                            ([key, curriculum]) => (
                                <tr
                                    className='management__table-row management__table-row--body'
                                    key={"curriculum_key_" + key}>
                                    <td className='management__table-data management__table-data--input'>
                                        <div className='d7__checkbox-container'>
                                            <input
                                                className='d7__checkbox'
                                                aria-label="checkbox"
                                                type='checkbox'
                                                onChange={e =>
                                                    handleSelectCurriculum(
                                                        e.target.checked,
                                                        curriculum.uid
                                                    )
                                                }
                                                checked={
                                                    curriculum.uid in
                                                    curriculum_selected
                                                }
                                            />
                                            <span className='checkmark'></span>
                                        </div>
                                    </td>
                                    <td className='management__table-data management__table-data--name'
                                        onClick={() => handleCurriculum( curriculum ) }>
                                        <div className='management__table-text pointer'>
                                            {curriculum.name}
                                        </div>
                                    </td>
                                    <td className='management__table-data'
                                        onClick={() => handleCurriculum( curriculum ) }>
                                        <div className='management__table-text pointer'>
                                            {curriculum.tenant.name}
                                        </div>
                                    </td>
                                    <td className='management__table-data'
                                        onClick={() => handleCurriculum( curriculum ) }>
                                        <div className='management__table-text pointer'>
                                            {curriculum.usage === 'Campaign' ?
                                                Language.getTitleCase('Phishing') : curriculum.usage }
                                        </div>
                                    </td>
                                    <td className='management__table-data'
                                        onClick={() => handleCurriculum( curriculum ) }>
                                        <div className='management__table-text pointer'>
                                            {curriculum.delivery_count}
                                        </div>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>

                {confirm != null && (
                    <ConfirmModal
                        msg={confirm.msg}
                        yesTxt={confirm.yes}
                        noTxt={confirm.no}
                        onYes={() => confirm.action(true)}
                        onNo={() => setState(prev => ({...prev, confirm: null })) }
                    />
                )}

                <Drip7OriginalModal>
                    {show_curriculum != null &&
                        <CurriculumModal
                            tenant_uid={tenant_uid}
                            tenants={tenants}
                            curriculum={show_curriculum}
                            showToast={showToast}
                            onClose={handleCloseCurriculum}
                        />
                    }
                </Drip7OriginalModal>
            </div>
        </div>
    )
}
