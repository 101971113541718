import React, { useState } from "react";
import Language from "../helpers/language";

//Define my view compon
//class Klass extends React.Component {
export const ConfirmModal = props => {
    const { yesTxt, noTxt, msg, onYes, onNo } = props;
    const className = props.className || "popup-modal"

    const safeValue = (msg, _default) => {
        return typeof msg == "undefined" || msg == null ? _default : msg;
    };

    const [state, setState] = useState({
        yes_txt: safeValue(yesTxt, Language.getSentenceCase('okay')),
        no_txt: safeValue(noTxt, Language.getSentenceCase('cancel')),
        message: safeValue(msg, Language.getSentenceCase('Are you sure?'))
    });
    const { yes_txt, no_txt, message } = state;

    return (
        <div className={className}>
            <div className='delete-user-modal'>
                <div className='delete-user-wrap'>
                    <p className='delete-user-message mt-2'>{message}</p>
                    <div className='delete-user-buttons mt-4 font-semibold'>
                        <button
                            className='clear-button'
                            type='button'
                            name='cancel'
                            onClick={onNo}>
                            {no_txt}
                        </button>
                        <button
                            className='solid-button'
                            type='button'
                            name='confirm'
                            onClick={onYes}>
                            {yes_txt}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
