import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language"
import { findContent } from "../../helpers/content";

import { Access, Languages } from "../../helpers/consts";
import {
    ExplanationTextWidget
} from "../../components/admin/explanation_text_widget";
import {
    ContentPhishWidget
} from "../../components/admin/content_phish_widget";
import {
    CurriculumSpecificWidget
} from "../../components/admin/curriculum_specific_widget";
import {
    ContentPhishPreview
} from "../../components/admin/content_phish_preview";
import { ModalTabsWidget } from "../../components/modal_tabs_widget";
import {Drip7Dropdown} from "../../components/drip7_dropdown";
import { Drip7Input } from "../../components/drip7_input";
import {Drip7Button} from "../../components/drip7_button";
import {Drip7ButtonDropdown} from "../../components/drip7_button_dropdown";
import {useStore} from "../../store";

export const PhishTemplateModal = props => {
    const { tenant_uid, showToast, onClose } = props;

    //Get user information
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));

    const props_access = { read: true, write: !('uid' in props.question), exec: true }

    const [state, setState] = useState({
        access: props_access,
        language: Languages.English,
        question: props.question,
        contents: [],
        tag_input: '',
        follow_up_uids: [],
        categories: [],
        category_questions: [],
        email_domains: [],
        selected_idx: 0,
        emails: [],
    });
    const { access, language, question, contents, follow_up_uids, categories, category_questions, email_domains, selected_idx, tag_input, emails } = state;

    const [content, placeholder, _] = findContent( language, contents );

    //Add the categories to question
    const clean_categories = categories.filter( x => (x.question_count <= 0) )
    const category = clean_categories.find( x => x.uid == question.category_uid ) || { name: "Categories" }

    //Grab the email domain
    const email_domain = email_domains.find( x => x.uid == question.email_domain_uid ) || { domain: 'Email Domain' }

    const tabs = [
        { name: Language.getTitleCase('preview'), icon: <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z" fill='currentColor' /> </svg> },
        { name: Language.getTitleCase('template'), icon: <svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M0.46875 2.625H12.6562C12.8906 2.625 13.125 2.41992 13.125 2.15625V1.21875C13.125 0.984375 12.8906 0.75 12.6562 0.75H0.46875C0.205078 0.75 0 0.984375 0 1.21875V2.15625C0 2.41992 0.205078 2.625 0.46875 2.625ZM14.5312 5.4375H2.34375C2.08008 5.4375 1.875 5.67188 1.875 5.90625V6.84375C1.875 7.10742 2.08008 7.3125 2.34375 7.3125H14.5312C14.7656 7.3125 15 7.10742 15 6.84375V5.90625C15 5.67188 14.7656 5.4375 14.5312 5.4375ZM12.6562 10.125H0.46875C0.205078 10.125 0 10.3594 0 10.5938V11.5312C0 11.7949 0.205078 12 0.46875 12H12.6562C12.8906 12 13.125 11.7949 13.125 11.5312V10.5938C13.125 10.3594 12.8906 10.125 12.6562 10.125Z' fill='currentColor'></path> </svg> },
        { name: Language.getTitleCase('follow up'), icon: <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M6.5625 3.7168V5.12305L7.41211 6.00195C7.20703 4.97656 7.5293 3.95117 8.26172 3.21875C8.84766 2.60352 9.63867 2.31055 10.459 2.31055H10.4883L8.81836 3.98047L9.25781 6.61719L11.8945 7.05664L13.5938 5.38672C13.5938 6.20703 13.2715 7.02734 12.6562 7.61328C12.3926 7.90625 12.0703 8.11133 11.748 8.25781C11.7773 8.28711 11.8359 8.3457 11.8945 8.375L12.8027 9.2832C13.0957 9.10742 13.3887 8.87305 13.6523 8.60938C14.7656 7.49609 15.2344 5.85547 14.8535 4.33203C14.7656 3.95117 14.502 3.6875 14.1504 3.57031C13.7695 3.48242 13.3887 3.59961 13.1543 3.83398L11.4258 5.5625L10.459 5.41602L10.3125 4.44922L12.041 2.75C12.3047 2.48633 12.3926 2.10547 12.3047 1.75391C12.1875 1.40234 11.9238 1.10938 11.543 1.02148C9.99023 0.640625 8.37891 1.08008 7.26562 2.22266C6.97266 2.51562 6.70898 2.83789 6.50391 3.21875H6.5625V3.7168ZM3.10547 14.1758C2.72461 14.5566 2.05078 14.5566 1.66992 14.1758C1.49414 14 1.37695 13.7363 1.37695 13.4727C1.37695 13.209 1.49414 12.9746 1.66992 12.7695L5.625 8.84375L4.62891 7.84766L0.703125 11.7734C0.234375 12.2422 0 12.8574 0 13.4727C0 14.1172 0.234375 14.7324 0.703125 15.1719C1.14258 15.6406 1.75781 15.875 2.40234 15.875C3.01758 15.875 3.63281 15.6406 4.10156 15.1719L7.03125 12.2422C6.76758 11.7734 6.5918 11.2461 6.5918 10.6895L3.10547 14.1758ZM14.6777 12.4766L11.25 9.04883C10.5469 8.375 9.55078 8.22852 8.73047 8.63867L5.625 5.5332V3.6875L1.875 0.875L0 2.75L2.8125 6.5H4.62891L7.73438 9.63477C7.35352 10.4551 7.4707 11.4512 8.14453 12.125L11.5723 15.582C12.0117 15.9922 12.6855 15.9922 13.125 15.582L14.6777 14.0293C15.0879 13.5898 15.0879 12.916 14.6777 12.4766Z' fill='currentColor'></path> </svg> },
    ]

    //Ensure specifics exists
    if (!("specifics" in content.content)) {
        content.content["specifics"] = [];
    }

    useEffect(() => {
        //Get a list of all emails
        Util.fetch_js('/human/list/', { tenant_uid },
            js => {
                const emails = js.users.filter(x => x.email !== usr_info.email)
                const me = {uid: usr_info.uid, name: 'Me', email: usr_info.email}
                setState(prev => ({ ...prev,
                    emails: [me].concat(emails)
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        )

        //Get a list of all email domains we provide
        Util.fetch_js( "/email_domain/list/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev,
                    email_domains: js.email_domains,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        )

        //Pull the details for the question
        if ("uid" in question) {
            Util.fetch_js( "/question/detail/", { question_uid: question.uid },
                js => {
                    let { access, question, contents, tags } = js;

                    const [_0, _1, update_contents] = findContent( language, contents );

                    //Now I have the correct access info, load my details
                    loadDetails( !access.write )

                    //Store the state
                    setState(prev => ({ ...prev,
                        access,
                        question,
                        tag_input: tags.map( x => x.name ).join(","),
                        contents: update_contents,
                        follow_up_uids: question.follow_up_uids,
                    }))
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }
        else {
            loadDetails( !access.write )
        }
    }, []);

    const loadDetails = ( read_only ) => {
        const access_mode = (read_only)? Access.ALL: Access.EXEC

        //Get all the categories
        Util.fetch_js( "/category/list/", { tenant_uid, access: access_mode },
            js => {
                setState(prev => ({ ...prev,
                    categories: js.categories
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        //Get the question info
        const payload = {
            tenant_uid,
            exclude_types: ["phish"],
            access: access_mode,
        }
        Util.fetch_js( "/question/list/", payload,
            js => {
                setState(prev => ({ ...prev,
                    category_questions: js.categories
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    const handleChange = event => {
        if ( !access.write ) {
            return
        }

        const name = event.target.name;
        const value = event.target.value;

        if (name == "tag_input") {
            setState(prev => ({ ...prev, [name]: value }))

        }
        else {
            let { question } = state;
            question[name] = value;

            setState(prev => ({ ...prev, question }))
        }
    };

    const handleDropdown = e => {
        if ( !access.write ) {
            return
        }

        let { question } = state;
        question[e.target.id] = e.target.value;

        setState({ ...state, question });
    };

    const handleLanguageDropdown = e => {
        const language = e.target.value;

        //Ensure contents is valid if we changing language
        const [_0, _1, update_contents] = findContent( language, contents );

        setState({ ...state, language, contents: update_contents });
    };

    const handleContentChange = (_, content) => {
        if ( !access.write ) {
            return
        }

        for (let i = 0; i < contents.length; i++) {
            if (contents[i].language == language) {
                contents[i].content = content;
            }
        }

        setState({ ...state, contents });
    };

    const handleExplanationChange = (_, explanation) => {
        if ( !access.write ) {
            return
        }

        for (let i = 0; i < contents.length; i++) {
            if (contents[i].language == language) {
                contents[i].explanation = explanation;
            }
        }

        setState({ ...state, contents });
    };

    const handleAddQuestion = question => {
        if ( !access.write ) {
            return
        }

        let { follow_up_uids } = state;
        follow_up_uids.push(question.uid);

        setState({ ...state, follow_up_uids });
    };

    const handleRemoveQuestion = question => {
        if ( !access.write ) {
            return
        }

        let { follow_up_uids } = state;

        //Find and remove the question
        const idx = follow_up_uids.findIndex(x => x == question.uid);
        if (idx >= 0 && idx < follow_up_uids.length) {
            follow_up_uids.splice(idx, 1);
        }

        setState({ ...state, follow_up_uids });
    };

    //Save before exiting
    const handleSave = () => {
        if ( !access.write ) {
            return
        }

        let payload = {
            name: question.name,
            content_type: "Phish",
            answer_type: "boolean",
            category_uid: question.category_uid,
            email_domain_uid: question.email_domain_uid,
            email_friendly: question.email_friendly,
            tags: tag_input.split(",").map( x => x.trim() ),
            contents,
            follow_up_uids,
        };

        //update?
        if (question.uid) {
            payload["question_uid"] = question.uid;

            Util.fetch_js( "/question/modify/", payload,
                js => {
                    showToast(Language.getSentenceCase('updated question'), 'successful');
                    onClose(false);
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }

        //Create
        else {
            Util.fetch_js( "/question/create/", payload,
                js => {
                    showToast(Language.getSentenceCase('created question'), 'successful');
                    onClose(false);
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
    };

    const handleSendTest = human => {
        const { subject, body } = content.content
        Util.fetch_js( "/phish/send_test/", { subject, body, question_uid: question.uid, human_uid: human.uid },
            js => {
                showToast(Language.getSentenceCase('Phish sent'), 'successful');
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    };

    return (
        <div className='basic-modal basic-modal--create-curriculum'
             tabIndex={-1}
             role='dialog'
             aria-labelledby='phish_templates_modal'>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content starts')}</p>
            <div className='basic-modal__head'>
                <h4>
                    {Language.getTitleCase('phish template')}
                    {!access.write ? ` ( ${Language.getTitleCase("read only")} )`: ''}
                </h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className='basic-modal__field-container basic-modal__field-container--add-phish'>
                <div className='basic-modal__field basic-modal__field---add-question'>
                    <label
                        className='basic-modal__label text-xs'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('name')}
                    </label>
                    <Drip7Input
                        id='newQuestionCategory'
                        placeholder={Language.getSentenceCase('Computer and cyber crimes')}
                        name='name'
                        value={question.name}
                        readOnly={!access.write}
                        onChange={handleChange}
                    />
                </div>

                <div className='basic-modal__field basic-modal__field---add-question'>
                    <label
                        className='basic-modal__label text-xs'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('Tags')}
                    </label>
                    <Drip7Input
                        id='newQuestionCategory'
                        placeholder={"tag1,tag2"}
                        name='tag_input'
                        value={tag_input}
                        readOnly={!access.write}
                        onChange={handleChange}
                    />                    
                </div>
            </div>

            <div className='basic-modal__field-container basic-modal__field-container--add-phish mt-4'>
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label className='basic-modal__label text-xs' htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('category')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id='category_uid'
                            value={category}
                            entries={clean_categories}
                            display={c => c.name}
                            changeValue={c => c.uid}
                            onChange={handleDropdown}
                            overflow='max-h-60'
                            />
                    </div>
                </div>

                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label className='basic-modal__label text-xs' htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('from email domain')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id='email_domain_uid'
                            value={email_domain}
                            entries={email_domains}
                            display={c => c.domain}
                            changeValue={c => c.uid}
                            onChange={handleDropdown}
                            overflow='max-h-60'
                            />
                    </div>
                </div>

                
                <div className='basic-modal__field basic-modal__field---add-question mt-4'>
                    <label
                        className='basic-modal__label text-xs'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('from friendly name')}
                    </label>
                    <Drip7Input
                        id='email_friendly'
                        placeholder={Language.getSentenceCase('from friendly name')}
                        name='email_friendly'
                        value={question.email_friendly}
                        readOnly={!access.write}
                        onChange={handleChange}
                    />
                </div>

                <div className='basic-modal__field basic-modal__field--add-question mt-4'>
                    <label
                        className='basic-modal__label text-xs'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('Test Phish')}
                    </label>
                    <Drip7ButtonDropdown
                        items={emails}
                        display={x => `( ${x.name} ) ${x.email}`}
                        onClick={handleSendTest}>
                        {Language.getTitleCase('Send Test')}
                    </Drip7ButtonDropdown>
                </div>
            </div>

            <div className='basic-modal__field-container mt-4'>
                <div className='basic-modal__language-selection'>
                    <div className='basic-modal__language-container'>
                        <Drip7Dropdown
                            id='language'
                            value={language}
                            entries={Language.getSupportedLanguages()}
                            display={x => Language.getTitleCase(x)}
                            changeValue={x => x}
                            onChange={handleLanguageDropdown}
                            overflow='max-h-60'
                            />
                    </div>
                </div>
            </div>

            <ModalTabsWidget
                tabs={tabs}
                selected_idx={selected_idx}
                onChange={selected_idx => setState(prev => ({...prev, selected_idx }))}/>


            {selected_idx == 0 &&
                <div className='basic-modal__field-container'>
                    <ContentPhishPreview
                        question={question}
                        content={content.content}
                        showToast={showToast}
                    />
                </div>
            }

            <div className='basic-modal__field-container' style={(selected_idx == 1)? {}: { display: 'none' }}>
                <div className='basic-modal__add-question basic-modal__add-question--text'>
                    <ContentPhishWidget
                        access={access}
                        question={question}
                        content={content.content}
                        showToast={showToast}
                        onChange={handleContentChange}
                    />
                </div>
            </div>

            {selected_idx == 2 &&
                <div className='basic-modal__field-container'>
                    <div className='basic-modal__add-question basic-modal__add-question--text'>
                        <ExplanationTextWidget
                            access={access}
                            explanation={content.explanation}
                            showToast={showToast}
                            onChange={handleExplanationChange}
                        />
                    </div>

                    <div className='basic-modal__add-question basic-modal__add-question--text'>
                        <CurriculumSpecificWidget
                            title={Language.getTitleCase('Follow up questions')}
                            categories={category_questions}
                            question_uids={follow_up_uids}
                            onAdd={handleAddQuestion}
                            onRemove={handleRemoveQuestion}
                        />
                    </div>
                </div>
            }

            <div className='basic-modal__button-container--save-bottom'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    disabled={!access.write}
                    onClick={handleSave}>
                    {Language.getTitleCase('save')}
                </button>
            </div>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content ends')}</p>
        </div>
    );
};
