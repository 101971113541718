import React, {useEffect, useState} from "react"

import Util from "../../helpers/util"
import Language from "../../helpers/language"

import { Drip7OriginalModal } from "../../modals/drip7_original_modal"
import {AdminLogModal} from "../../modals/admin/admin_log_modal";
import { Pagination } from "../../components/pagination";
import { Spinner } from "../../components/spinner";

export const ContentLogTab = (props) => {
    const { tenant_uid, showToast } = props

    const [state, setState] = useState({
        runs: [],
        logs: [],
        users: {},
        questions: {},
        queues: {},
        search: '',
        page: 1,
        pagecount: 0,
        perpage: 1,
        total: 0,
        delivery_run_uid: null,
        loading: false
    })
    const { logs, runs, users, questions, queues, search, page, pagecount, perpage, total, delivery_run_uid, loading } = state

    useEffect(() => {
        fetchLogs(0)
    }, [])

    useEffect(() => {
        if ( delivery_run_uid === null || delivery_run_uid === undefined || delivery_run_uid === "" ) {
            return
        }

        setState( prev => ({ ...prev, loading: true }) )

        Util.fetch_js( '/admin_log/view_run/', { tenant_uid, delivery_run_uid },
            js => {
                const users = {}
                const questions = {}
                const queues = {}
                js.users.forEach( x => users[x.uid] = x )
                js.questions.forEach( x => questions[x.uid] = x )
                js.queues.forEach( x => queues[x.uid] = x )

                setState( prev => ({ ...prev,
                    logs: js.logs,
                    users,
                    questions,
                    queues,
                    search: '',
                    loading: false,
                }) )
            },
            (err, code) => {
                showToast( err, 'failure' )
            })
    }, [delivery_run_uid])

    const fetchLogs = (p) => {
        Util.fetch_js( '/admin_log/content_run/', { tenant_uid, page: p },
            js => {
                setState( prev => ({ ...prev,
                    runs: js.logs,
                    pagecount: js.pages,
                    perpage: js.perpage,
                    total: js.total
                }) )
            },
            (err, code) => {
                showToast( err, 'failure' )
            })
    }

    const handleChanged = (e) => {
        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }

    const handleViewRun = (delivery_run_uid) => {
        setState(prev => ({ ...prev, delivery_run_uid }))
    }

    const handlePageChange = (e) => {
        // const p = e.target.value
        setState(prev => ({ ...prev,
            page: e
        }))
        fetchLogs(e-1)
    }

    if ( loading ) {
        return (
            <div className="flex flex-col justify-center items-center mt-20 w-full">
                <h1 className="text-2xl text-gray-500">Loading...</h1>
                <Spinner className="w-16 h-16 mt-10" />
            </div>
        )
    }

    if ( delivery_run_uid !== null && delivery_run_uid !== undefined && delivery_run_uid !== "" ) {
        const delivery = runs.find( x => x.uid === delivery_run_uid ) || {msg: 'Delivery log', timestamp: 0}
        return (
            <div className="px-4 sm:px-6 lg:px-8 mt-6">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold text-gray-900">
                            {delivery.msg} on {Util.friendlyDate( Util.epoch( delivery.timestamp_on ) )}
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A list of log events for this delivery.
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => setState( prev => ({ ...prev, delivery_run_uid: null }) )}
                            className="block rounded-md bg-drip7 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-drip7 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-drip7">
                            Back
                        </button>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block py-2 align-middle">
                            <table className="divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    <th scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                                        Message
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Queue
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        User
                                    </th>
                                    <th scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Question
                                    </th>
                                    <th scope="col"
                                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                                        <span
                                            className="sr-only">Timestamp</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody
                                    className="divide-y divide-gray-200 bg-white">
                                {logs.map( ( log, idx ) => (
                                    <tr key={`info_${idx}`}
                                        className="even:bg-gray-50">
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 text-wrap">
                                            {log.msg}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-wrap">
                                            {log.queue_uid in queues ? queues[log.queue_uid].name : ''}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-wrap">
                                            {log.user_uid in users ? `${users[log.user_uid].name} ${users[log.user_uid].email}` : 'N/A'}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-wrap">
                                            {log.question_uid in questions ? Util.truncate( questions[log.question_uid].name, 50 ) : 'N/A'}
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                                            {Util.friendlyDateTime( Util.epoch( log.timestamp_on ) )}
                                        </td>
                                    </tr>) )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>)
    }

    console.log( 'search', search )
    const filtered_runs = runs.filter( x => search === "" || x.msg.toLowerCase().includes(search.toLowerCase()) )

    return (
        <div className='central__main central__main--questions'>
            <div className='central__head'>
                <div className='central__head-container'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('search by name, endpoint') + '...'}
                            id="search"
                            value={search}
                            onChange={handleChanged}
                        />
                    </div>
                </div>
                <Pagination
                    page={page}
                    per_page={perpage}
                    total={total}
                    onPage={handlePageChange}
                />
            </div>

            <table className='questions__table px-2'>
                <tbody>
                <tr className='questions__table-row questions__table-row--head'>
                    <th className='questions__table-head'>
                        <h5 className='questions__table-heading'>
                            {Language.getTitleCase('message')}
                        </h5>
                    </th>
                    <th className='questions__table-head'>
                        <h5 className='questions__table-heading'>
                            {Language.getTitleCase('Timestamp')}
                        </h5>
                    </th>
                </tr>

                {Object.entries(filtered_runs).map(([key, run]) => (
                    <tr className='questions__table-row questions__table-row--body'
                        key={"admin_log_key_" + key}>
                        <td className='questions__table-data questions__table-data--name'>
                            <h5 className='questions__table-text'
                                onClick={() => handleViewRun(run.uid)}>
                                {run.msg}
                            </h5>
                        </td>
                        <td className='questions__table-data'>
                            <h5 className='questions__table-text'
                                onClick={() => handleViewRun(run.uid)}>
                                {Util.friendlyDateTime( Util.epoch(run.timestamp_on))}
                            </h5>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}
