import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Util from "../helpers/util";


export const Drip7ButtonDropdown = props => {
    const { children } = props
    const idBtn = props.idBtn || ""
    const nameBtn = props.nameBtn || ""
    const disabled = props.disabled || false
    const color = props.color || "drip7"
    const className = props.className || ""
    const onClick = props.onClick || (() => {})
    const style = props.style || {}
    const items = props.items || []
    const align = props.align === 'left'? 'left-0': 'right-0'
    const display = props.display || ((x) => x)

    return (
        <div className="inline-flex rounded-md h-10">
            <button
                id={idBtn}
                name={nameBtn}
                disabled={disabled}
                style={style}
                type="button"
                onClick={e => onClick(items[0])}
                className={Util.classNames(`relative inline-flex items-center rounded-l-md bg-${color} px-6 py-4 text-sm font-semibold text-white ring-1 ring-inset ring-${color} hover:${color} focus:z-10`, className)}>
                {children}
            </button>
            <Menu as="div" className="relative -ml-px block">
                <MenuButton className={`relative inline-flex items-center rounded-r-md bg-${color} px-2 py-2.5 text-white border-l border-gray-300 focus:z-10`}>
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon aria-hidden="true" className="size-5" />
                </MenuButton>
                <MenuItems
                    transition
                    className={Util.classNames(
                        'h-60 overflow-y-auto',
                        `absolute z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`,
                        align
                    )}>
                    <div className="py-1 w">
                        {items.map((item) => (
                            <MenuItem key={item}>
                                <div className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer"
                                onClick={e => onClick(item)}>
                                    {display(item)}
                                </div>
                            </MenuItem>
                        ))}
                    </div>
                </MenuItems>
            </Menu>
        </div>
    )
}

