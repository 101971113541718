import React, {useEffect, useState} from "react";
import Util from "../../helpers/util";
import { BadgePopup } from "../../components/user/badge_popup";
import { Badge } from "../../components/badge";
import { BadgeTable } from "../../components/badge_table";
import { useStore } from "../../store";
import Language from "../../helpers/language";
import NonBadgePlaceholder1 from "../../assets/images/badges/NonBadgePlaceholder1.jpg"
import ContentLoader from "react-content-loader";
import Categories from "../../components/user/categories";


export const BadgeStatus = (props) => {
    const { onPathChange, showToast } = props

    const [state, setState] = useState({
        loading: false,
        show_badge: null,
        badges: [],
    })
    const { show_badge, badges, loading } = state

    useEffect(() => {
        Util.fetch_js( "/client/badge/list_by_tenant/", {},
            js => {
                setState( prev => ({...prev,
                    badges: js.badges,
                    loading: false
                }))
            },
            (reason, code) => {
                setState(prev => ({...prev,
                    loading: false
                }))
                console.log(reason, "failure");
            }
        );

        onPathChange("badge-status")
        setState(prev => ({...prev,
            loading: true
        }))
    }, [])

    return (
        <div className="py-10 bg-gray-50">
            <h1 className="text-3xl font-semibold">
                {Language.getTitleCase("your badges")}
            </h1>
            <ul role="list" className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {badges.map((b, idx) => (
                    <li key={`badge_${idx}`}
                        className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                        <div className="flex flex-1 flex-col p-8">
                            <img alt="" src={(b.completed || !b.badge.obscure) ? b.badge.image : NonBadgePlaceholder1 }
                                 className="mx-auto size-32 shrink-0 rounded-full shadow-md" />
                            <h3 className="mt-6 text-sm font-medium text-gray-900">
                                {b.badge.name}
                            </h3>
                            <dd className="text-sm text-gray-500">
                                {Math.round(b.owned_perc * 100)}% {Language.getLowerCase("players own this badge")}
                            </dd>
                            <dl className="mt-4 flex grow flex-col justify-between">
                                <dt className="sr-only">Title</dt>
                                <dd className="text-sm text-gray-600">
                                    {b.contents[0]?.message}
                                </dd>
                                <dt className="sr-only">Role</dt>
                                <dd className="mt-3">
                                    {b.completed &&
                                    <span className={Util.classNames(
                                            "inline-flex items-center rounded-full px-4 py-1 text-xs font-medium ring-1 ring-inset",
                                            (b.completed)? "bg-green-50 text-green-700 ring-green-600/20": "bg-gray-50 text-gray-600 ring-gray-300"
                                        )}>
                                        {b.completed? Language.getTitleCase("completed") : `${b.percentage}% ${Language.getTitleCase("earned")}`}
                                    </span>
                                    }

                                    {!b.completed &&
                                    <div className="mx-20 my-1 text-xs font-medium bg-gray-200 rounded-full h-6 ring-1 ring-inset ring-gray-300">
                                        <div className="h-full bg-drip7 rounded-full transition-all duration-500 ease-out flex items-center justify-center text-white text-sm font-medium"
                                            style={{ width: `${Math.max(b.percentage, 10)}%` }} >
                                        </div>
                                        <span className="relative -top-5 text-gray-800">
                                            {b.completed? Language.getTitleCase("completed") : `${b.percentage}% ${Language.getTitleCase("earned")}`}
                                        </span>
                                    </div>
                                    }
                                </dd>
                            </dl>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
