import React, { useState, useEffect } from "react";
import Language from "../../helpers/language";
import Util from "../../helpers/util";
import {ConfirmModalV2} from "../../modals/confirm_modal_v2";

export const AnswerTypeAccept = React.forwardRef((props, ref) => {
    const { answer, value, reviewedPolicy, onChanged, showToast } = props;

    const is_answered = (value != undefined && value != null && Util.xstr(value) != '')
    const safe_value = Util.xbool( value )

    React.useImperativeHandle(ref, () => ({
        reset: () => {
        },
        attemptAnswer: () => {
            return false;
        }
    }));

    const [state, setState] = useState({
        confirm_refuse: false,
    })
    const { confirm_refuse } = state

    const calcResult = ( answer, safe_value ) => {
        const ans_correct = true//(answer && 'correct' in answer && answer.correct != undefined && answer.correct != null)? answer.correct: safe_value
        const is_correct = (Util.xbool(ans_correct) === Util.xbool(safe_value))

        return {
            ans_correct,
            is_correct,
        }
    }

    const handleChange = (e) => {
        if ( !reviewedPolicy ) {
            showToast( "Please review the policy first", "info" )
            return
        }

        const safe_value = e.target.value
        const { ans_correct, is_correct } = calcResult( answer, safe_value )

        //Open the "are you sure" popup
        if ( !is_correct && !confirm_refuse ) {
            setState(prev => ({ ...prev,
                confirm_refuse: true,
            }))
            return
        }

        onChanged(safe_value, is_correct, ans_correct.toString());
    };

    const handleConfirmRefuse = (confirmed) => {
        setState(prev => ({ ...prev,
            confirm_refuse: false,
        }))

        //If the user didn't confirm, do nothing
        if ( !confirmed ) {
            return
        }

        onChanged(false, false, false);
    }

    const klass = exp => {
        const { ans_correct, is_correct } = calcResult( answer, safe_value )

        //No answer? do nothing
        if ( !is_answered ) {
            return ""
        }

        //If the answer isn't correct and this is the answer given, show it
        if ( !is_correct && safe_value === exp ) {
            return " daily-questions__" + exp + "--incorrect";
        }

        //No matter if the answer is correct or not, if this is the correct answer, mark it
        if ( ans_correct === exp ) {
            return " daily-questions__" + exp + "--correct";
        }

        return "";
    };

    return (
        <div className='daily-questions__true-or-false'>
            <ConfirmModalV2
                open={confirm_refuse}
                danger={true}
                title={Language.getSentenceCase("Refuse policy?")}
                desc={Language.getSentenceCase("Are you sure you want to refuse the policy?")}
                yes={Language.getSentenceCase("Refuse")}
                onClose={handleConfirmRefuse}
                />

            <button
                className={"daily-questions__true" + klass(true)}
                value={true}
                onClick={handleChange}>
                {Language.getSentenceCase('acknowledge')}
            </button>
            <button
                className={"daily-questions__false" + klass(false)}
                value={false}
                onClick={handleChange}>
                {Language.getSentenceCase('refuse')}
            </button>
        </div>
    );
});
